import React, { useState } from 'react';
import TeamSection from '../team';
import './style.css';
import vimg from '../../images/volunteer.jpg';
import axios from 'axios';

const Volunteer = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        notes: '',
        file: null
    });
    const [submissionStatus, setSubmissionStatus] = useState(null); // Add state for submission status
    const [error, setError] = useState({}); // Add state for form errors

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        setError({
            ...error,
            [name]: '' // Clear the error message for the field being changed
        });
    };

    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            file: e.target.files[0]
        });
        setError({
            ...error,
            file: '' // Clear the error message for the file field
        });
    };

    const resetForm = () => {
        setFormData({
            name: '',
            email: '',
            subject: '',
            notes: '',
            file: null
        });
        setError({});
    };

    const validateForm = () => {
        const newError = {};
        if (!formData.name) newError.name = 'Please enter your name';
        if (!formData.email) newError.email = 'Please enter your email';
        if (!formData.subject) newError.subject = 'Please enter your subject';
        if (!formData.notes) newError.notes = 'Please enter your notes';
        if (!formData.file) newError.file = 'Please upload your CV';
        setError(newError);
        return Object.keys(newError).length === 0;
    };

    const SubmitHandler = async (e) => {
        e.preventDefault();
        console.log('Form submitted'); // Add this line to check if the handler is called

        if (!validateForm()) {
            setSubmissionStatus('error');
            return;
        }

        const data = new FormData();
        data.append('name', formData.name);
        data.append('email', formData.email);
        data.append('subject', formData.subject);
        data.append('notes', formData.notes);
        data.append('file', formData.file);

        try {
            const response = await axios.post('https://api.sungirls.org/send_volunteer_email', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('Email sent successfully:', response.data);
            setSubmissionStatus('success'); // Set submission status to success
            resetForm(); // Reset the form after successful submission
        } catch (error) {
            console.error('There was an error sending the email:', error);
            setSubmissionStatus('error'); // Set submission status to error
        }
    };

    return (
        <div className="volunteer-page">
            <div className="volunteer-area section-padding">
                <div className="volunteer-wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="volunteer-item">
                                    <div className="volunteer-img-wrap">
                                        <div className="volunter-img">
                                            <img src={vimg} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="volunteer-contact">
                                    <div className="volunteer-contact-form">
                                        <form onSubmit={SubmitHandler} className="contact-validation-active" id="contact-form">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                                                    <input type="text" className="form-control" name="name" id="name" placeholder="First Name" value={formData.name} onChange={handleChange} />
                                                    <p className="error-message">{error.name ? error.name : ''}</p>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group clearfix">
                                                    <input type="email" className="form-control" name="email" id="email" placeholder="Email" value={formData.email} onChange={handleChange} />
                                                    <p className="error-message">{error.email ? error.email : ''}</p>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                                                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" value={formData.subject} onChange={handleChange} />
                                                    <p className="error-message">{error.subject ? error.subject : ''}</p>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group form-group-in">
                                                    <label htmlFor="file">Upload Your CV</label>
                                                    <input id="file" type="file" className="form-control" name="file" onChange={handleFileChange} />
                                                    <i className="ti-cloud-up"></i>
                                                    {formData.file && <p>Selected file: {formData.file.name}</p>}
                                                    <p className="error-message">{error.file ? error.file : ''}</p>
                                                </div>
                                                <div className="col-lg-12 col-12 form-group">
                                                    <textarea className="form-control" name="notes" id="notes" placeholder="Case Description..." value={formData.notes} onChange={handleChange}></textarea>
                                                    <p className="error-message">{error.notes ? error.notes : ''}</p>
                                                </div>
                                                <div className="submit-area col-lg-12 col-12">
                                                    <button type="submit" className="theme-btn submit-btn">Send Message</button>
                                                </div>
                                            </div>
                                        </form>
                                        {submissionStatus === 'success' && <p className="success-message">Email sent successfully!</p>}
                                        {submissionStatus === 'error' && <p className="error-message">There was an error sending the email. Please try again later.</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TeamSection addclass="v2" />
        </div>
    );
};

export default Volunteer;