import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { fetchBlogs } from '../../services/strapiService';
import './style.css';
import blog2 from '../../images/blog-details/author.jpg';
import blog3 from '../../images/blog-details/comments-author/img-1.jpg';
import blog4 from '../../images/blog-details/comments-author/img-2.jpg';
import blog5 from '../../images/blog-details/comments-author/img-3.jpg';
import blog6 from '../../images/blog/admin.jpg';

const BlogDetailsFullwidth = () => {
  const [blogs, setBlogs] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getBlogs = async () => {
      try {
        const blogsData = await fetchBlogs();
        setBlogs(blogsData);
        console.log('Blogs state:', blogsData);
      } catch (error) {
        setError(error);
        console.error('Error fetching blogs:', error);
      }
    };

    getBlogs();
  }, []);

  const renderContent = (content) => {
    return content.map((block, index) => {
      switch (block.type) {
        case 'paragraph':
          return <p key={index}>{block.children.map(child => child.text).join('')}</p>;
        case 'heading':
          return React.createElement(`h${block.level}`, { key: index }, block.children.map(child => child.text).join(''));
        case 'image':
          return <img key={index} src={block.image.url} alt={block.image.alternativeText} />;
        case 'quote':
          return <blockquote key={index}>{block.children.map(child => child.text).join('')}</blockquote>;
        default:
          return null;
      }
    });
  };

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!blogs.length) {
    return <div>Loading...</div>;
  }

  const handlePrevious = (currentId) => {
    const previousId = currentId - 1;
    if (previousId > 0) {
      navigate(`/blog-details/${previousId}`);
    }
  };

  const handleNext = (currentId) => {
    const nextId = currentId + 1;
    if (nextId <= blogs.length) {
      navigate(`/blog-details/${nextId}`);
    }
  };

  return (
    <section className="tp-blog-single-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-lg-12 col-12">
            <div className="tp-tp-blog-content clearfix">
              {blogs.map(blog => (
                <div key={blog.id} className="post">
                  <div className="entry-media">
                    {blog.attributes.image && <img src={`${process.env.REACT_APP_API_BASE_URL}${blog.attributes.image.url}`} alt={blog.attributes.title} />}
                  </div>
                  <ul className="entry-meta">
                    <li><img src={blog6} alt=""/> By {blog.attributes.author}</li>
                    <li>{new Date(blog.attributes.publishedAt).toLocaleDateString()}</li>
                    <li><i className="ti-heart"></i> 35</li>
                  </ul>
                  <h2>{blog.attributes.title}</h2>
                  {renderContent(blog.attributes.content)}
                  <div className="tag-share clearfix">
                    <div className="tag">
                      <ul>
                        <li><Link to={`/blog-details/${blog.id}`}>Education</Link></li>
                        <li><Link to={`/blog-details/${blog.id}`}>Empowerment</Link></li>
                        <li><Link to={`/blog-details/${blog.id}`}>Women</Link></li>
                      </ul>
                    </div>
                    <div className="share">
                      <ul>
                                         
                        {/* Facebook */}
                        <li>
                          <Link to="https://wa.me/93749221870" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-whatsapp fa-lg"></i>
                          </Link>
                        </li>
                      
                        {/* LinkedIn */}
                        <li>
                          <Link to="https://www.linkedin.com/company/sun-s-girls-organization/" target="_blank" rel="noopener noreferrer">
                            <i className="ti-linkedin"></i>
                          </Link>
                        </li>
                      
                        {/* Instagram */}
                        <li>
                          <Link to="https://www.instagram.com/suns_girls_organization/" target="_blank" rel="noopener noreferrer">
                            <i className="ti-instagram"></i>
                          </Link>
                        </li>
                      
                        
                      </ul>
                    </div>
                  </div>
                  <div className="author-box">
                    <div className="author-avatar">
                      <Link to={`/blog-details/${blog.id}`} target="_blank"><img src={blog2} alt=""/>  </Link>
                    </div>
                    <div className="author-content">
                      <Link to={`/blog-details/${blog.id}`} className="author-name">Sun's Girl Organization</Link>
                      <p>It is vital for the international community, governments, and local organizations to prioritize education for Afghan girls. Providing resources, support, and opportunities for girls to learn, grow, and contribute will pave the way for a prosperous and equitable Afghanistan.</p>
                      <div className="author-btn">
                        <Link to={`/blog-details/${blog.id}`}>All Post From Author</Link>
                      </div>
                    </div>
                  </div>
                  <div className="more-posts clearfix">
                    <div className="previous-post">
                      <button onClick={() => handlePrevious(blog.id)} disabled={blog.id <= 1}>
                        <span className="post-control-link">Previous</span>
                      </button>
                    </div>
                    <div className="next-post">
                      <button onClick={() => handleNext(blog.id)} disabled={blog.id >= blogs.length}>
                        <span className="post-control-link">Next post</span>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              <div className="tp-blog-single-section wrap-even">
                <div className="comments-area">
                  <div className="comments-section">
                    <h3 className="comments-title">Comments</h3>
                    <ol className="comments">
                      {blogs.map(blog => (
                        <li key={blog.id} className="comment even thread-even depth-1" id="comment-1">
                          <div id="div-comment-1">
                            <div className="comment-theme">
                              <div className="comment-image"> <img src={blog3} alt=""/> </div>
                            </div>
                            <div className="comment-main-area">
                              <div className="comment-wrapper">
                                <div className="comments-meta">
                                  <h4>Abrahim <span className="comments-date">October 28, 2024 At 9.00am</span></h4>
                                </div>
                                <div className="comment-area">
                                  <p>Incredible effort by SGO! These workshops are essential for shaping a brighter future for Afghan girls. </p>
                                  <div className="comments-reply">
                                    <Link className="comment-reply-link" to={`/blog-details/${blog.id}`}><i className="fa fa-reply" aria-hidden="true"></i><span>Reply</span></Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <ul className="children">
                            <li className="comment">
                              <div>
                                <div className="comment-theme">
                                  <div className="comment-image"> <img src={blog4} alt=""/></div>
                                </div>
                                <div className="comment-main-area">
                                  <div className="comment-wrapper">
                                    <div className="comments-meta">
                                      <h4>Lily Watson <span className="comments-date">December 8, 2024 At 11.26am</span></h4>
                                    </div>
                                    <div className="comment-area">
                                      <p>A transformative initiative! Empowering Afghan girls through STEM is the need of the hour. </p>
                                      <div className="comments-reply">
                                        <Link className="comment-reply-link" to={`/blog-details/${blog.id}`}><span><i className="fa fa-reply" aria-hidden="true"></i> Reply</span></Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <ul>
                                <li className="comment">
                                  <div>
                                    <div className="comment-theme">
                                      <div className="comment-image"><img src={blog5} alt=""/> </div>
                                    </div>
                                    <div className="comment-main-area">
                                      <div className="comment-wrapper">
                                        <div className="comments-meta">
                                          <h4>Azar Kohistani <span className="comments-date">October 19, 2024 At 1.00am</span></h4>
                                        </div>
                                        <div className="comment-area">
                                          <p>This blog beautifully highlights the importance of STEM education. Looking forward to seeing the impact of the upcoming workshop! </p>
                                          <div className="comments-reply">
                                            <Link className="comment-reply-link" to={`/blog-details/${blog.id}`}><span><i className="fa fa-reply" aria-hidden="true"></i> Reply</span></Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      ))}
                    </ol>
                  </div>
                </div>
                <div className="comment-respond">
                  <h3 className="comment-reply-title">Leave a Comment</h3>
                  <form method="post" id="commentform" className="comment-form" onSubmit={SubmitHandler}>
                    <div className="form-inputs">
                      <input placeholder="Name" type="text"/>
                      <input placeholder="Email" type="email"/>
                      
                    </div>
                    <div className="form-textarea">
                      <textarea id="comment" placeholder="Write Your Comments..."></textarea>
                    </div>
                    <div className="form-submit">
                      <input id="submit" value="Reply" type="submit"/>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogDetailsFullwidth;