/*import React, { Component } from 'react';
import emailjs from 'emailjs-com';

class ContactForm extends Component {
    state = {
        name: '',
        email: '',
        subject: '',
        lastname: '',
        notes: '',
        error: {},
        successMessage: ''
    };

    changeHandler = (e) => {
        const error = { ...this.state.error };
        error[e.target.name] = '';

        this.setState({
            [e.target.name]: e.target.value,
            error,
            successMessage: ''
        });
    };

    submitHandler = (e) => {
        e.preventDefault();

        const { name, email, subject, lastname, notes, error } = this.state;

        // Validate form fields
        let isValid = true;
        if (name === '') {
            error.name = "Please enter your name";
            isValid = false;
        }
        if (email === '') {
            error.email = "Please enter your email";
            isValid = false;
        }
        if (subject === '') {
            error.subject = "Please enter your subject";
            isValid = false;
        }
        if (lastname === '') {
            error.lastname = "Please enter your lastname";
            isValid = false;
        }
        if (notes === '') {
            error.notes = "Please enter your note";
            isValid = false;
        }

        if (!isValid) {
            this.setState({ error });
            return;
        }

        const templateParams = {
            name,
            email,
            subject,
            lastname,
            notes
        };

        emailjs.send('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', templateParams, 'YOUR_USER_ID')
            .then(response => {
                console.log('Email sent successfully:', response.status, response.text);
                this.setState({
                    name: '',
                    email: '',
                    subject: '',
                    lastname: '',
                    notes: '',
                    error: {},
                    successMessage: 'Email sent successfully!'
                });
            })
            .catch(error => {
                console.error('There was an error sending the email:', error);
                this.setState({
                    successMessage: 'There was an error sending the email. Please try again later.'
                });
            });
    };

    render() {
        const { name, email, subject, lastname, notes, error, successMessage } = this.state;

        return (
            <form onSubmit={this.submitHandler} className="form">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input value={name} onChange={this.changeHandler} type="text" name="name" placeholder="Name"/>
                            <p>{error.name ? error.name : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input value={lastname} onChange={this.changeHandler} type="text" name="lastname" placeholder="Lastname"/>
                            <p>{error.lastname ? error.lastname : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input onChange={this.changeHandler} value={email} type="email" name="email" placeholder="Email"/>
                            <p>{error.email ? error.email : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input onChange={this.changeHandler} value={subject} type="text" name="subject" placeholder="Subject"/>
                            <p>{error.subject ? error.subject : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-field">
                            <textarea name="notes" value={notes} onChange={this.changeHandler} placeholder="Message"></textarea>
                            <p>{error.notes ? error.notes : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-submit">
                            <button type="submit" className="theme-btn">Send Message</button>
                        </div>
                    </div>
                    {successMessage && (
                        <div className="col-lg-12">
                            <p>{successMessage}</p>
                        </div>
                    )}
                </div>
            </form>
        );
    }
}

export default ContactForm;*/



import React, { Component } from 'react';
import axios from 'axios';

class ContactForm extends Component {
    state = {
        name: '',
        email: '',
        subject: '',
        lastname: '',
        notes: '',
        error: {},
        successMessage: ''
    };

    changeHandler = (e) => {
        const error = { ...this.state.error };
        error[e.target.name] = '';

        this.setState({
            [e.target.name]: e.target.value,
            error,
            successMessage: ''
        });
    };

    submitHandler = (e) => {
        e.preventDefault();

        const { name, email, subject, lastname, notes, error } = this.state;

        // Validate form fields
        let isValid = true;
        if (name === '') {
            error.name = "Please enter your name";
            isValid = false;
        }
        if (email === '') {
            error.email = "Please enter your email";
            isValid = false;
        }
        if (subject === '') {
            error.subject = "Please enter your subject";
            isValid = false;
        }
        if (lastname === '') {
            error.lastname = "Please enter your lastname";
            isValid = false;
        }
        if (notes === '') {
            error.notes = "Please enter your note";
            isValid = false;
        }

        if (!isValid) {
            this.setState({ error });
            return;
        }

        axios.post('https://api.sungirls.org/send_contact_email', { // Update this URL
            name,
            email,
            subject,
            lastname,
            notes
        })
        .then(response => {
            if (response.data.status === 'success') {
                this.setState({
                    name: '',
                    email: '',
                    subject: '',
                    lastname: '',
                    notes: '',
                    error: {},
                    successMessage: 'Email sent successfully!'
                });
            } else {
                this.setState({
                    successMessage: response.data.message
                });
            }
        })
        .catch(error => {
            console.error('There was an error sending the email!', error);
            this.setState({
                successMessage: 'There was an error sending the email. Please try again later.'
            });
        });
    };

    render() {
        const { name, email, subject, lastname, notes, error, successMessage } = this.state;

        return (
            <form onSubmit={this.submitHandler} className="form">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input value={name} onChange={this.changeHandler} type="text" name="name" placeholder="Name"/>
                            <p>{error.name ? error.name : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input value={lastname} onChange={this.changeHandler} type="text" name="lastname" placeholder="Lastname"/>
                            <p>{error.lastname ? error.lastname : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input onChange={this.changeHandler} value={email} type="email" name="email" placeholder="Email"/>
                            <p>{error.email ? error.email : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input onChange={this.changeHandler} value={subject} type="text" name="subject" placeholder="Subject"/>
                            <p>{error.subject ? error.subject : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-field">
                            <textarea name="notes" value={notes} onChange={this.changeHandler} placeholder="Message"></textarea>
                            <p>{error.notes ? error.notes : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-submit">
                            <button type="submit" className="theme-btn">Send Message</button>
                        </div>
                    </div>
                    {successMessage && (
                        <div className="col-lg-12">
                            <p>{successMessage}</p>
                        </div>
                    )}
                </div>
            </form>
        );
    }
}

export default ContactForm;