import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:1337';

const API_TOKEN = process.env.REACT_APP_STRAPI_API_TOKEN || 'your-default-token';
const headers = {
  Authorization: `Bearer ${API_TOKEN}`,
};

export const fetchBlogs = async () => {
  try {
    const response = await axios.get(`${API_URL}/blogs`, { headers });
    return response.data.data; // Access the nested data
  } catch (error) {
    console.error('Error fetching blogs:', error);
    throw error;
  }
};

export const fetchBlogById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/blogs/${id}?populate=*`, { headers });
    return response.data.data; // Access the nested data
  } catch (error) {
    console.error('Error fetching blog:', error);
    throw error;
  }
};
/*export const fetchEvents = async () => {
  const response = await fetch(`${API_URL}/events`);
  if (!response.ok) {
    throw new Error('Failed to fetch events');
  }
  const data = await response.json();
  return data.data; // Ensure you return the correct data structure
};*/



export const fetchEvents = async () => {
  try {
    const response = await axios.get(`${API_URL}/events`, { headers });
    console.log('Fetched events:', response.data.data);
    return response.data.data; // Access the nested data
  } catch (error) {
    console.error('Error fetching events:', error);
    throw error;
  }
};