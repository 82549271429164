import React from 'react';
import Logo from '../../images/logo/logo.png';
import { Link } from 'react-router-dom';
import HeaderTopbar from '../HeaderTopbar';
import MobileMenu from '../../components/MobileMenu';
import './style.css';

const Header = () => {
    const SubmitHandler = (e) => {
        e.preventDefault();
    };

    return (
        <div className="middle-header">
            <HeaderTopbar />
            <div className="header-style-3">
                <div className="container">
                    <div className="header-content">
                        <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-4 col-4">
                                <div className="logo">
                                    <Link to="/home" title="">
                                        <img src={Logo} alt="logo" />Sun's Girl Organization
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-8 d-lg-block d-none">
                                <nav>
                                    <ul>
                                        <li><Link className="active" to="/home" title="">Home</Link></li>
                                        <li><Link to="/about" title="">About</Link>
                                        <ul>
                                                <li><Link to="/donate" title="">Donate</Link></li>
                                                
                                                <li><Link to="/impact" title="">Our Impact</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link to="/causes" title="">Causes</Link></li>
                                        <li><Link to="/event" title="">Event</Link></li>
                                        <li><Link to="/volunteer" title="">Volunteer</Link>
                                            
                                        </li>
                                        <li><Link to="/blogs">Blog</Link>
                                            <ul>
                                                <li><Link to="/blog-details/1" title="">Details</Link></li>
                                            </ul>
                                        </li>
                                        
                                        <li><Link to="/contact" title="">Contact</Link></li>
                                    </ul>
                                </nav>
                            </div>
                            <div className="col-lg-1 col-md-6 col-sm-6 col-6">
                                <div className="contact">
                                    <div className="cart-search-contact">
                                        <div className="header-search-form-wrapper">
                                            <button className="search-toggle-btn">
                                                <i className="fi flaticon-magnifying-glass"></i>
                                            </button>
                                            <div className="header-search-form">
                                                <form onSubmit={SubmitHandler}>
                                                    <div>
                                                        <input type="text" className="form-control" placeholder="Search here..." />
                                                        <button type="submit"><i className="ti-search"></i></button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-2 col-2">
                                <MobileMenu />
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;