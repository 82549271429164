import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import test1 from '../../images/testimonials/img-1.png';
import test2 from '../../images/testimonials/img-2.png';
import test3 from '../../images/testimonials/img-3.png';
import test4 from '../../images/ts.png';
import test5 from '../../images/testimonials/img-4.png'; // New image
import test6 from '../../images/testimonials/img-5.png'; // New image
import test7 from '../../images/testimonials/img-6.png'; // New image

import { PrevArrow, NextArrow } from './CustomArrows'; // Import custom arrow components
import './style.css';

class Testimonial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {
    return (
      <section className="testimonials-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
            <h2 className="section-title-m">Board Members</h2> {/* Added heading */}
              <div className="testimonials-slider">
                <div className="testimonial-thumb-active">
                  <Slider
                    asNavFor={this.state.nav2}
                    fade={true}
                    prevArrow={<PrevArrow />}
                    nextArrow={<NextArrow />}
                    ref={slider => (this.slider1 = slider)}
                  >
                    <div className="test-img">
                      <img src={test2} alt="Testimonial 1" />
                    </div>
                    <div className="test-img">
                      <img src={test1} alt="Testimonial 2" />
                    </div>
                    <div className="test-img">
                      <img src={test5} alt="Testimonial 3" /> {/* New testimonial image */}
                    </div>
                    <div className="test-img">
                      <img src={test6} alt="Testimonial 4" /> {/* New testimonial image */}
                    </div>
                    <div className="test-img">
                      <img src={test7} alt="Testimonial 5" /> {/* New testimonial image */}
                    </div>
                  </Slider>
                </div>
                <div className="testimonial-content-active text-center">
                  <Slider
                    asNavFor={this.state.nav1}
                    ref={slider => (this.slider2 = slider)}
                    slidesToShow={1}
                    swipeToSlide={true}
                    arrows={false}
                    fade={true}
                  >
                    <div className="grid">
                      <ul>
                        <li><i className="fa fa-star"></i></li>
                        <li><i className="fa fa-star"></i></li>
                        <li><i className="fa fa-star"></i></li>
                        <li><i className="fa fa-star"></i></li>
                        <li><i className="fa fa-star"></i></li>
                      </ul>
                      <p>Sana Kohistani, born in 1998 in Kapisa, Afghanistan, currently resides in Kabul. She earned her Doctor of Dental Medicine (DMD) degree from Kabul University of Medical Sciences. An education activist, she is committed to providing free and quality education, advocating for equal access regardless of discrimination.</p>
                      <div className="info">
                        <h5>Sana Kohistani</h5>
                        <p>Founder</p>
                      </div>
                    </div>
                    <div className="grid">
                      <ul>
                        <li><i className="fa fa-star"></i></li>
                        <li><i className="fa fa-star"></i></li>
                        <li><i className="fa fa-star"></i></li>
                        <li><i className="fa fa-star"></i></li>
                        <li><i className="fa fa-star"></i></li>
                      </ul>
                      <p>Frohar Hamidi is a Vital Voices Visionary leader and education advocate for girls education in Afghanistan, with an educational background in healthcare, research and entrepreneurship; passionate about SDG leadership with a focus on quality education, gender equality and good health and well-being.</p>
                      <div className="info">
                        <h5>Frohar Hamidi</h5>
                        <p>Founder</p>
                      </div>
                    </div>
                    <div className="grid"> {/* New testimonial content */}
                      <ul>
                        <li><i className="fa fa-star"></i></li>
                        <li><i className="fa fa-star"></i></li>
                        <li><i className="fa fa-star"></i></li>
                        <li><i className="fa fa-star"></i></li>
                        <li><i className="fa fa-star"></i></li>
                      </ul>
                      <p>Nayera  Azar Kohistani is an Afghan women's rights activist who experienced the first Taliban regime in her country. she was a teacher in a  High School when the Taliban regained power. Kohistani has led numerous protests against the oppressive rules imposed on women and, after being imprisoned for her activism, left Afghanistan in 2022. She is a prominent advocate against "gender apartheid" and the criminalization of gender in Afghanistan.</p>
                      <div className="info">
                        <h5>Nayera Azar Kohistani</h5>
                        <p>Co-founder</p>
                      </div>
                    </div>
                    <div className="grid"> {/* New testimonial content */}
                      <ul>
                        <li><i className="fa fa-star"></i></li>
                        <li><i className="fa fa-star"></i></li>
                        <li><i className="fa fa-star"></i></li>
                        <li><i className="fa fa-star"></i></li>
                        <li><i className="fa fa-star"></i></li>
                      </ul>
                      <p>Mohammad Abrahim Kohistani is pursuing a BBA at the University of the People. An education activist and English instructor at Sun's Girls Organization (SGO) since 2022, supporting Afghan girls who have been denied access to education. He is also an active member of the City Environmental Protection and Cleanliness Association.</p>
                      <div className="info">
                        <h5>Mohammad Abrahim Kohistani</h5>
                        <p>Program coordinator</p>
                      </div>
                    </div>
                    <div className="grid"> {/* New testimonial content */}
                      <ul>
                        <li><i className="fa fa-star"></i></li>
                        <li><i className="fa fa-star"></i></li>
                        <li><i className="fa fa-star"></i></li>
                        <li><i className="fa fa-star"></i></li>
                        <li><i className="fa fa-star"></i></li>
                      </ul>
                      <p>Maseeullah Yousofi is a dedicated student pursuing a degree in Biotechnology at Kabul University and Computer Science at the American University of Afghanistan. He is a committed women's and girls' rights activist.</p>
                      <div className="info">
                        <h5>Maseeullah Yousofi</h5>
                        <p>Project manager</p>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="testi-shape">
          <img src={test3} alt="Shape 1" />
        </div>
        <div className="testi-shape2">
          <img src={test4} alt="Shape 2" />
        </div>
      </section>
    );
  }
}

export default Testimonial;
